import * as types from '../mutation-types'

// initial state
const state = {
  loading: 0,
  edit: false
}

// getters
const getters = {}

// actions
const actions = {

}

// mutations
const mutations = {
  [types.UI_SET_EDIT](state, edit) {
    state.edit = edit
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}