import { createApp, defineAsyncComponent } from 'vue'
import { createStore } from 'vuex'
import { createI18n } from 'vue-i18n';
import storeConfig from './store'
import axios from 'axios';

// i18nsConfig.locale = 'fr';

const ColisProduits = defineAsyncComponent(() =>
  import('./components/ColisProduits.vue')
)

const store = createStore(storeConfig)

console.log(document.location);
console.log(document.location.hostname);

const main_url = document.location.hostname == 'localhost' ? 'https://shopiles.remi-zehr.dev.oliv.fr' : ''
const get__nosecure = document.location.hostname == 'localhost' ? '?nosecure' : ''

console.log('startApp');
axios.post(main_url + '/plugin_projet/plugin_projet_colis/api_trads').then(function (resp) {
  console.log(resp);
  let i18n = createI18n({
    locale: 'fr', fallbackLocale: 'fr', messages: { 'fr': resp.data }
    // locale: 'fr', fallbackLocale: 'fr', messages: { 'fr': { 'colis_produit.declaration': 'Déclaration douanière sss' } }
  });
  console.log(i18n);

  createApp({})
    .use(store)
    .use(i18n)
    .component('colis-produits', ColisProduits)
    .mount('#colis-produits')
  console.log('appCreate');
});
