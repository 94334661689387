import * as types from '../mutation-types'
import axios from 'axios';
import _ from 'lodash';
import { main_url, get_nosecure } from './../../env.js';

// initial state
const state = {
  colis: [],
  produits: [],
  pays: []
}

// getters
const getters = {}

// actions
const actions = {
  async getColisApi({ commit }, colis_ids) {
    console.log('getColisApi', colis_ids)
    try {
      let resp = await axios.post(main_url + '/plugin_projet/plugin_projet_colis/api_get_colis' + get_nosecure, { colis_ids: colis_ids });

      commit('COLIS_SET_COLIS', _.concat(resp.data.colis, state.colis))
      commit('COLIS_SET_PRODUITS', _.concat(resp.data.produits, state.produits))
      commit('COLIS_SET_PAYS', resp.data.pays)
    } catch (error) {
      console.log(error)
    }
  },
  async deleteProduit({ state, commit, dispatch }, { id, token }) {
    console.log(id, token)

    let produit = {}
    let produits = []
    if (id) {
      produit = state.produits.filter(p => p.id == id)
      await axios.post(main_url + '/plugin_projet/plugin_projet_colis/api_delete_colis' + get_nosecure, { id });
      produits = state.produits.filter(p => p.id != id)
    } else {
      produit = state.produits.filter(p => p.token == token)
      produits = state.produits.filter(p => p.token != token)
    }

    commit('UI_SET_EDIT', false)
    commit('COLIS_SET_PRODUITS', produits)
    dispatch('isValide', produit[0].colis_id)

  },
  async duplicateProduit({ state, commit }, { id }) {
    let produit = {}
    _.forEach(state.produits, _produit => {
      console.log(_produit);
      if (_produit.id == id) {
        produit = _.cloneDeep(_produit)
        produit['id'] = null;
        produit['token'] = null;
      }
    })

    console.log(produit);

    let resp = await axios.post(main_url + '/plugin_projet/plugin_projet_colis/api_save_colis' + get_nosecure, { produit });
    produit['id'] = resp.data.id

    console.log(state.produits);
    await commit('COLIS_ADD_PRODUIT', produit)
    console.log(state.produits);
    commit('UI_SET_EDIT', true)
  },
  addProduit({ commit, state }, colis_id) {
    let produits = state.produits.filter(p => p.id == null && p.colis_id == colis_id)
    if (produits.length != 0) return
    let produit = {
      id: null,
      token: _.uniqueId('token_'),
      colis_id,
      label: '',
      prix_ht: '',
      prix_tva: '',
      quantite: '',
      origine: 'FR',
      hs_code: '',
      data: {},
    }

    commit('COLIS_ADD_PRODUIT', produit)
    commit('UI_SET_EDIT', true)
  },
  async saveProduit({ state, commit, dispatch }, { produit }) {
    let resp = await axios.post(main_url + '/plugin_projet/plugin_projet_colis/api_save_colis' + get_nosecure, { produit });

    let produits = _.cloneDeep(state.produits);
    _.forEach(produits, (_produit, k) => {
      if (resp.data.token != null && resp.data.token == _produit.token) {
        produits[k] = produit
        produits[k]['id'] = resp.data.id
        produits[k]['token'] = null
      }
      if (resp.data.token == null && resp.data.id == _produit.id) {
        produits[k] = produit
        produits[k]['id'] = resp.data.id
        produits[k]['token'] = null
      }
    })

    commit('UI_SET_EDIT', false)
    commit('COLIS_SET_PRODUITS', produits)
    dispatch('isValide', produit.colis_id)
    return { id: resp.data.id, token: null }
  },
  updateProduit({ commit }, { colis_id }) {
    console.log(colis_id)
  },
  async uploadFacture({ dispatch }, { colis_id, file }) {
    console.log('uploadFacture')
    var formData = new FormData();
    formData.append("colis_id", colis_id);
    formData.append("facture_fileu", file);

    let res = await axios.post(main_url + '/plugin_projet/plugin_projet_colis/api_colis_facture' + get_nosecure, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    dispatch('isValide', colis_id)

    return res.data
  },
  async checkColis({ dispatch }, { colis_id, declaration_validee_client }) {
    console.log('uploadFacture')
    var formData = new FormData();
    formData.append("colis_id", colis_id);
    formData.append("declaration_validee_client", declaration_validee_client);

    let res = await axios.post(main_url + '/plugin_projet/plugin_projet_colis/api_colis_check_declaration' + get_nosecure, formData)
    dispatch('isValide', colis_id)
    return res.data
  },
  async isValide({ }, colis_id) {
    console.log('isValide', colis_id);
    var formData = new FormData();
    formData.append("colis_id", colis_id);
    let res = await axios.post(main_url + '/plugin_projet/plugin_projet_colis/api_colis_valide' + get_nosecure, formData)
    console.log(res);
    console.log(res.data);

    let elem = document.querySelector('.colis_' + colis_id + ' .zone_add_panier');
    if (elem) {
      if (res.data === 1) {
        elem.classList.add('valide_proforma')
      } else {
        elem.classList.remove('valide_proforma')
      }
    }
  }

}

// mutations
const mutations = {
  [types.COLIS_SET_COLIS](state, colis) {
    state.colis = colis
    console.log(colis);
  },
  [types.COLIS_SET_PRODUITS](state, produits) {
    state.produits = produits
    console.log('set_produits');
  },
  [types.COLIS_SET_PAYS](state, pays) {
    state.pays = pays
    console.log('set_pays');
  },
  [types.COLIS_ADD_PRODUIT](state, produit) {
    state.produits.push(produit)
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  state,
  getters,
  actions,
  mutations
}