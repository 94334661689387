import * as actions from './actions'
import ui from './modules/ui'
import colis from './modules/colis'


// const debug = process.env.NODE_ENV !== 'production'

export default {
	state:{
		'mode' : null
	},
  actions,
  modules: {
    ui,
    colis,
  },
  strict: false,
  // plugins: debug ? [createLogger()] : []
}